import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import SEO from '@components/seo';
import renderParagraphs from '@components/paragraphs';
import getFormatDate from '@helpers/get-format-date';
import Image, { ImageProps } from '@components/image';
import renderModule from '@components/module';
import Layout from '@layouts/default-layout';
import { headerHeight } from '@components/header/header.styled';
import Wrapper from '@components/wrapper.styled';
import {
  TopMeta,
  MetaContainer,
  ImageContainer,
  PostContainer,
  MetaInfo,
  Categories,
  SubMeta,
  TextSubMeta,
  PostBody,
  AuthorPhotoConteiner,
  AuthorName,
  PrefixAuthorName,
  Date,
  TitleContainer,
  Description,
  Title,
} from '@components/paragraphs/paragraphs.shared.styled';
import RelativePosts from '@components/relative-posts';
import { PostsListProps } from '@components/posts-list/relative-posts-list';
import { CategoriesPost } from '@components/module/module.shared.styled';
import { Category } from '@components/posts-list/posts-list.shared.styled';
import SharePost from '@components/share-post';
import generateId from '@helpers/generate-id';
import { Container } from '../pages';

export interface CategoriesProps {
  category: {
    id: string;
    document: {
      data: {
        color: string;
        name: {
          text: string;
        };
      };
    };
  };
}

const postPageIdGenerator = generateId();

interface PostProps {
  data: {
    prismicGeneralConfig: {
      data: {
        favicon?: {
          url?: string;
        };
      };
    };
    prismicPostPageConfig: {
      data: {
        title_for_relative_posts: {
          text: string;
        };
        body: [];
      };
    };
    currentPost: {
      edges: {
        node: {
          uid: string;
          last_publication_date: string;
          tags: string[];
          data: {
            post_image: ImageProps;
            post_title: {
              text: string;
            };
            author_name: {
              text: string;
            };
            author_photo: ImageProps;
            body: [];
            post_description: {
              text: string;
            };
          };
        };
      }[];
    };
    relativePosts?: PostsListProps;
    restPosts?: PostsListProps;
  };
}

const Post = ({
  data: {
    currentPost,
    relativePosts,
    restPosts,
    prismicPostPageConfig,
    prismicGeneralConfig: {
      data: { favicon },
    },
  },
}: PostProps): JSX.Element | null => {
  if (!currentPost) return null;

  const [displayRelativePosts, setDisplayRelativePosts] = useState(relativePosts?.edges || []);

  const post = currentPost.edges[0].node;
  const { title_for_relative_posts, body } = prismicPostPageConfig.data;

  useEffect(() => {
    const relativePostsLength = relativePosts?.edges?.length || 0;
    const restPostsEdges = restPosts?.edges;

    if (!relativePostsLength && restPostsEdges?.length) {
      setDisplayRelativePosts(restPostsEdges);
    } else if (relativePostsLength < 3 && restPostsEdges?.length) {
      const restPostsArray = restPostsEdges?.slice(0, 3 - relativePostsLength);
      const resultPostsArray = displayRelativePosts?.concat(restPostsArray);

      setDisplayRelativePosts(resultPostsArray);
    }
  }, []);

  const getPostUrl = (): string => (typeof window !== 'undefined' ? window.location.href : '');

  return (
    <Layout>
      <SEO title={post.data.post_title.text || 'Blog'} faviconHref={favicon?.url || ''} />
      <Container style={{ marginTop: headerHeight }}>
        <CategoriesPost />
        {(post.data.post_title.text || post.data.post_description.text) && (
          <Wrapper>
            {post.data.post_image.url && (
              <ImageContainer>
                <Image
                  gatsbyImageData={post.data.post_image.gatsbyImageData}
                  url={post.data.post_image.url}
                  alt={post.data.post_image.url}
                />
              </ImageContainer>
            )}
            <PostContainer>
              <MetaContainer>
                <MetaInfo>
                  <TopMeta>
                    {post.tags?.length ? (
                      <Categories>
                        {post.tags.map(
                          (tag) =>
                            tag && (
                              <Category key={postPageIdGenerator.next().value}>
                                {tag}&nbsp;
                              </Category>
                            )
                        )}
                      </Categories>
                    ) : null}
                    <SubMeta>
                      {(post.data.author_photo.gatsbyImageData || post.data.author_photo.url) && (
                        <AuthorPhotoConteiner>
                          <Image
                            gatsbyImageData={post.data.author_photo.gatsbyImageData}
                            url={post.data.author_photo.url}
                            alt={post.data.author_photo.alt}
                          />
                        </AuthorPhotoConteiner>
                      )}
                      <TextSubMeta>
                        {post.data.author_name.text && (
                          <AuthorName>
                            <PrefixAuthorName>by </PrefixAuthorName>
                            {post.data.author_name.text}
                          </AuthorName>
                        )}
                        <Date>on {getFormatDate(post.last_publication_date)}</Date>
                      </TextSubMeta>
                    </SubMeta>
                  </TopMeta>
                  <SharePost
                    url={getPostUrl()}
                    title={post.data.post_title.text}
                    description={post.data.post_description.text}
                  />
                </MetaInfo>
              </MetaContainer>
              <PostBody>
                <TitleContainer>
                  {post.data.post_title.text && (
                    <Title type="title" stringText={post.data.post_title.text} textColor="black" />
                  )}
                  {post.data.post_description.text && (
                    <Description
                      type="title"
                      stringText={post.data.post_description.text}
                      textColor="black"
                    />
                  )}
                </TitleContainer>
                {post.data.body.map(({ slice_type, primary }) => (
                  <React.Fragment key={postPageIdGenerator.next().value}>
                    {renderParagraphs(slice_type, primary)}
                  </React.Fragment>
                ))}
              </PostBody>
            </PostContainer>
            {displayRelativePosts.length > 0 && (
              <RelativePosts edges={displayRelativePosts} title={title_for_relative_posts.text} />
            )}
          </Wrapper>
        )}
        {body.map(
          (module) =>
            module && (
              <React.Fragment key={postPageIdGenerator.next().value}>
                {renderModule(module)}
              </React.Fragment>
            )
        )}
      </Container>
    </Layout>
  );
};

export default Post;

export const query = graphql`
  query PostQuery($uid: String, $categoriesArray: [String]) {
    prismicGeneralConfig {
      ...GeneralConfigFragment
    }
    prismicPostPageConfig {
      ...PostPageConfigFragment
    }
    currentPost: allPrismicPost(filter: { uid: { eq: $uid } }) {
      edges {
        node {
          ...PostFragment
        }
      }
    }
    relativePosts: allPrismicPost(
      filter: { tags: { in: $categoriesArray }, uid: { ne: $uid } }
      limit: 3
      sort: { fields: first_publication_date, order: DESC }
    ) {
      edges {
        node {
          ...PostPreviewFragment
        }
      }
    }
    restPosts: allPrismicPost(
      filter: { uid: { ne: $uid } }
      limit: 3
      sort: { fields: first_publication_date, order: DESC }
    ) {
      edges {
        node {
          ...PostPreviewFragment
        }
      }
    }
  }
`;
