import styled from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => `
    padding: 1.5rem 0 0;
    width: 100%;
    border-top: 1px solid ${theme.colors.emerald};

    @media (max-width: ${theme.breakpoints.lg}) {
      padding: 0;
      border-top: none;
      width: fit-content;
    }

    @media (max-width: ${theme.breakpoints.sm}) {
      padding-top: 2rem;
    }
  `}
`;

export const Title = styled.p`
  font-size: 0.75rem;
  line-height: 0.87rem;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--violet);
`;

export const Media = styled.div`
  margin: 1rem 0 0;
  display: flex;
`;

export const MediaIcon = styled.div`
  position: relative;
  margin: 0 0.75rem 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.25rem;
  height: 2.25rem;
  background-color: var(--default);
  color: var(--light);
  border-radius: 100%;
  cursor: pointer;
`;

export const MediaShareIcon = styled(MediaIcon)`
  margin: 0;
`;
