import React, { useState } from 'react';
import TwitterIcon from '@components/svg-icons/twitter';
import LinkedinIcon from '@components/svg-icons/linkedin';
import RedditinIcon from '@components/svg-icons/reddit';
import ShareIcon from '@components/svg-icons/share';
import Tooltip from '@components/tooltip';
import copyOnClick from '@helpers/copy-on-ckick';
import generateId from '@helpers/generate-id';
import { Container, Title, Media, MediaIcon, MediaShareIcon } from './share-post.styled';

const sharePostIdGenerator = generateId();

interface SharePostProps {
  url: string;
  title: string;
  description: string;
}

const SharePost = ({ url, title, description }: SharePostProps): JSX.Element => {
  const [isCopied, setIsCopied] = useState(false);

  const tooltipText = isCopied ? 'Copied!' : `Copy URL`;

  const handleButtonMouseLeave = () => {
    setIsCopied(false);
  };

  const media = [
    {
      name: 'twitter',
      link: `https://twitter.com/intent/tweet?url=${url}&text=${title}`,
      icon: <TwitterIcon />,
    },
    {
      name: 'linkedin',
      link: `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}&summary=${description}`,
      icon: <LinkedinIcon />,
    },
    {
      name: 'reddit',
      link: `https://www.reddit.com/submit?url=${url}&title=${title}`,
      icon: <RedditinIcon />,
    },
    {
      name: 'share',
      link: url,
      icon: <ShareIcon />,
    },
  ];

  return (
    <Container>
      <Title>Share</Title>
      <Media>
        {media.map(({ link, icon, name }) => {
          return name !== 'share' ? (
            <a href={link} target="_blank" rel="noreferrer" key={sharePostIdGenerator.next().value}>
              <MediaIcon>{icon}</MediaIcon>
            </a>
          ) : (
            <Tooltip
              text={tooltipText}
              handleMouseLeave={handleButtonMouseLeave}
              key={sharePostIdGenerator.next().value}
            >
              <MediaShareIcon
                role="button"
                onClick={() => copyOnClick(url, () => setIsCopied(true))}
              >
                {icon}
              </MediaShareIcon>
            </Tooltip>
          );
        })}
      </Media>
    </Container>
  );
};

export default SharePost;
